import { useEffect, useState, useMemo } from 'react';
import { useMeeting } from '../meeting/meetingProviderContextDef';

const useParticipant = (
  participantId,
  {
    onStreamEnabled = () => {},
    onStreamDisabled = () => {},
    onMediaStatusChanged = () => {}
  } = {}
) => {
  const [webcamStream, setwebcamStream] = useState(null);
  const [micStream, setMicStream] = useState(null);
  const [screenShareStream, setScreenShareStream] = useState(null);

  const meeting = useMeeting();

  const {
    participants,
    localParticipant,
    activeSpeakerId,
    mainParticipant,
    pinState
  } = useMemo(() => {
    const participants = meeting?.participants;
    const localParticipant = meeting?.localParticipant;
    const activeSpeakerId = meeting?.activeSpeakerId;
    const mainParticipant = meeting?.mainParticipant;
    const pinState = meeting?.pinnedParticipants?.get(participantId) || {
      cam: false,
      share: false
    };

    // const micOn = meeting?.participants?.get(participantId).micOn || false;
    // const webcamOn = meeting?.participants?.get(participantId).webcamOn || false;

    return {
      participants,
      localParticipant,
      activeSpeakerId,
      mainParticipant,
      pinState
    };
  }, [meeting]);

  const participant = participants?.get(participantId);

  const [webcamOn, setWebcamOn] = useState(participant?.webcamOn);
  const [micOn, setMicOn] = useState(participant?.micOn);

  const setTrack = stream => {
    if (stream.track.readyState === 'live') {
      switch (stream.kind) {
        case 'video':
          setwebcamStream(stream);
          break;
        case 'audio':
          setMicStream(stream);
          break;
        case 'share':
          setScreenShareStream(stream);
          break;
        default:
          break;
      }
    }
  };

  const unSetTrack = stream => {
    if (stream.track.readyState !== 'live') {
      switch (stream.kind) {
        case 'video':
          setwebcamStream(null);
          break;
        case 'audio':
          setMicStream(null);
          break;
        case 'share':
          setScreenShareStream(null);
          break;
        default:
          break;
      }
    }
  };

  const _handleStreamEnabled = stream => {
    setTrack(stream);
    onStreamEnabled(stream);
  };

  const _handleStreamDisabled = stream => {
    unSetTrack(stream);
    onStreamDisabled(stream);
  };

  const _handleMediaStatusChanged = data => {
    const { kind, peerId, newStatus } = data;
    if (kind == 'audio') {
      setMicOn(newStatus);
    } else if (kind == 'video') {
      setWebcamOn(newStatus);
    }
    onMediaStatusChanged({ kind, peerId, newStatus });
  };

  const setQuality = quality => {
    participant?.setQuality(quality);
  };

  const setViewPort = (width, height) => {
    participant?.setViewPort(width, height);
  };

  const enableMic = () => {
    participant?.enableMic();
  };
  const disableMic = () => {
    participant?.disableMic();
  };
  const enableWebcam = () => {
    participant?.enableWebcam();
  };
  const disableWebcam = () => {
    participant?.disableWebcam();
  };
  const pin = data => {
    participant?.pin(data);
  };
  const unpin = data => {
    participant?.unpin(data);
  };
  const getAudioStats = async () => {
    return participant?.getAudioStats();
  };
  const getVideoStats = async () => {
    return participant?.getVideoStats();
  };

  const consumeWebcamStreams = () => {
    participant?.consumeWebcamStreams();
  };
  const consumeMicStreams = () => {
    participant?.consumeMicStreams();
  };
  const stopConsumingWebcamStreams = () => {
    participant?.stopConsumingWebcamStreams();
  };
  const stopConsumingMicStreams = () => {
    participant?.stopConsumingMicStreams();
  };
  const switchTo = async data => {
    await participant?.switchTo(data);
  };

  useEffect(() => {
    const streams = participant?.streams;

    if (streams) {
      streams.forEach(stream => {
        setTrack(stream);
      });
    }

    if (participant?.micOn) {
      setMicOn(micOn);
    }

    if (participant?.webcamOn) {
      setWebcamOn(webcamOn);
    }

    participant?.on('stream-enabled', _handleStreamEnabled);

    participant?.on('stream-disabled', _handleStreamDisabled);

    participant?.on('media-status-changed', _handleMediaStatusChanged);

    return () => {
      participant?.off('stream-enabled', _handleStreamEnabled);

      participant?.off('stream-disabled', _handleStreamDisabled);

      participant?.off('media-status-changed', _handleMediaStatusChanged);
    };
  }, [participant]);

  return {
    displayName: participant?.displayName,
    participant,
    webcamStream,
    micStream,
    screenShareStream,
    webcamOn: webcamOn,
    micOn: micOn,
    screenShareOn: !!screenShareStream,
    isLocal: localParticipant?.id === participantId,
    isActiveSpeaker: activeSpeakerId === participantId,
    isMainParticipant: mainParticipant?.id === participantId,
    pinState,

    //
    consumeMicStreams,
    consumeWebcamStreams,
    stopConsumingMicStreams,
    stopConsumingWebcamStreams,
    setQuality,
    setViewPort,
    enableMic,
    disableMic,
    enableWebcam,
    disableWebcam,
    pin,
    unpin,
    switchTo,
    getAudioStats,
    getVideoStats
  };
};

export default useParticipant;
